import React, { Component } from "react";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../state/mapStateAndDispatch/cart";

const isWithinRange = require("date-fns/is_within_range");
const getDay = require("date-fns/get_day");

class pickupOrDelivery extends Component {
  handleChange = event => {
    const { value } = event.target;
    const { setOrderType } = this.props;
    setOrderType(value);
  };

  render() {
    const {
      orderType,
      pickupAvailable,
      deliveryAvailable,
      sundayDeliveryAvailable,
      mondayDeliveryAvailable,
      morningDeliveryAvailable,
      deliveryWaitMinutes,
      pickupWaitMinutes,
      total,
    } = this.props;

    function morningAvailabilityCheck() {
      if (morningDeliveryAvailable) {
        return true;
      }
      const today = new Date();
      const start = new Date();
      start.setHours(15);
      start.setMinutes(30);
      const end = new Date();
      end.setHours(22);
      end.setMinutes(0);
      return isWithinRange(today, start, end);
    }

    function weekendAvailabilityCheck() {
      // ! SUNDAY DELIVERY ACTUALLY REFERS TO SATURDAY AND SUNDAY
      if (
        !sundayDeliveryAvailable &&
        (getDay(new Date()) === 0 || getDay(new Date()) === 6)
      ) {
        return false;
      }

      return true;
    }

    function mondayAvailabilityCheck() {
      if (!mondayDeliveryAvailable && getDay(new Date()) === 1) {
        return false;
      }
      return true;
    }

    function enableDeliveries() {
      if (!deliveryAvailable) {
        return false;
      }

      if (
        !mondayAvailabilityCheck() ||
        !weekendAvailabilityCheck() ||
        !morningAvailabilityCheck()
      ) {
        return false;
      }

      return true;
    }

    function priceIsOver50() {
      return total >= 50;
    }

    function buildDeliveryLabel() {
      if (!priceIsOver50()) {
        return "We only offer deliveries for orders over $50.00!";
      }
      return `Delivery (Wait Time: ${deliveryWaitMinutes} Minutes)`;
    }

    function redTextPicker() {
      if (!mondayAvailabilityCheck()) {
        return "this Monday";
      }
      if (!weekendAvailabilityCheck()) {
        return "this weekend";
      }
      if (!morningAvailabilityCheck()) {
        return "this morning";
      }

      return "right now";
    }

    return (
      <form>
        <p style={{ textAlign: "left" }}>Order Type</p>
        <RadioGroup
          aria-label="Choose Delivery or Pickup"
          name="Choose Delivery or Pickup"
          value={orderType}
          onChange={this.handleChange}
        >
          {pickupAvailable && (
            <FormControlLabel
              value="pickup"
              control={<Radio color="primary" />}
              label={`Pickup (Wait Time: ${pickupWaitMinutes} Minutes)`}
            />
          )}
          {enableDeliveries() ? (
            <FormControlLabel
              value="delivery"
              control={<Radio color="primary" />}
              label={buildDeliveryLabel()}
              disabled={!priceIsOver50()}
            />
          ) : (
            <div style={{ color: "red", textAlign: "left" }}>
              Due to understaffing, we cannot offer
              <br />
              delivery {redTextPicker()}. You can still order <br />
              from{" "}
              <a href="https://www.doordash.com/store/chopstick-express-st.-petersburg-244848/?event_type=autocomplete&pickup=false">
                Doordash
              </a>
              ,{" "}
              <a href="https://www.grubhub.com/restaurant/chopstick-express-3946-4th-st-n-st-petersburg/3351711">
                Grubhub
              </a>
              , or{" "}
              <a href="https://www.ubereats.com/store/chopstick-express-inc/-rDfOjvISl28X1bfF5JY7A">
                Uber Eats
              </a>
            </div>
          )}
        </RadioGroup>
      </form>
    );
  }
}

pickupOrDelivery.propTypes = {
  orderType: PropTypes.string.isRequired,
  setOrderType: PropTypes.func.isRequired,
  pickupAvailable: PropTypes.bool.isRequired,
  deliveryAvailable: PropTypes.bool.isRequired,
  sundayDeliveryAvailable: PropTypes.bool.isRequired,
  mondayDeliveryAvailable: PropTypes.bool.isRequired,
  morningDeliveryAvailable: PropTypes.bool.isRequired,
  deliveryWaitMinutes: PropTypes.string.isRequired,
  pickupWaitMinutes: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(pickupOrDelivery);
