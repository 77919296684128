import React, { Component } from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../state/mapStateAndDispatch/cart";
import SendPriceToRedux from "./sendPriceToRedux";

class priceInfo extends Component {
  constructor(props) {
    super(props);
    this.setTip = this.setTip.bind(this);
    this.state = {
      settingTip: false,
    };
  }

  setTip(e, maskedValue, floatValue) {
    const { setTip } = this.props;
    setTip(floatValue);
  }

  setTotal = number => {
    const { setTotal } = this.props;
    setTotal(number);
  };

  setSubtotal = number => {
    const { setSubtotal } = this.props;
    setSubtotal(number);
  };

  toCustomerInfo = number => {
    const { failureNotification, orderType } = this.props;

    if (orderType === "delivery") {
      if (number >= 20) {
        navigate("/customerinfo");
      } else {
        failureNotification();
      }
    } else {
      navigate("/customerinfo");
    }
  };

  render() {
    const { order, orderType, tip } = this.props;
    const FixOrder = () => {
      let value = 0;
      for (let i = 0, len = order.length; i < len; i += 1) {
        if (Object.prototype.hasOwnProperty.call(order[i], "sauces")) {
          for (const item of Object.keys(order[i].sauces)) {
            if (typeof order[i].sauces[item].price === "number") {
              value += order[i].sauces[item].price;
            }
          }
        }
      }
      return value;
    };
    let noDelivery = false;
    let subtotal = parseFloat(
      order.reduce(
        (sum, item) =>
          parseFloat(sum) +
          parseFloat(item.price) +
          (item.rnPrice || 0) +
          (item.meatPrice || 0),
        0
      )
    ).toFixed(2);
    if (typeof FixOrder() === "number") {
      subtotal = (
        parseFloat(subtotal) + parseFloat(FixOrder().toFixed(2))
      ).toFixed(2);
    }
    const tax = subtotal * 0.07;
    let total = subtotal * 1.07 + parseFloat(tip);
    if (orderType === "delivery") {
      total = parseFloat(total) + 1.9;
    }
    if (parseFloat(total) < 50 && orderType === "delivery") {
      noDelivery = true;
    }
    return (
      <div>
        <p style={{ textAlign: "right" }}>Subtotal: ${subtotal}</p>
        <p style={{ textAlign: "right" }}>Tax: ${tax.toFixed(2)}</p>
        {orderType === "delivery" && (
          <p style={{ textAlign: "right" }}>Delivery Fee: $1.90</p>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <p>
            Tip{!this.state.settingTip && <span> (${tip.toFixed(2)})</span>}:
          </p>
          &nbsp;&nbsp;&nbsp;
          {this.state.settingTip ? (
            <CurrencyInput
              prefix="$"
              style={{
                textAlign: "right",
                fontSize: "1.15em",
                width: "118px",
                border: "1px solid black",
                backgroundColor: "rgb(252,252,252)",
                height: "55px",
              }}
              allowNegative={false}
              autoFocus
              value={tip}
              onChangeEvent={this.setTip}
            />
          ) : (
            <button
              type="button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  settingTip: true,
                });
              }}
            >
              Set Tip
            </button>
          )}
        </div>
        <br />
        <br />
        <p style={{ textAlign: "right" }}>
          {noDelivery && (
            <span style={{ color: "hsl(358, 80%, 55%)", fontSize: "0.88em" }}>
              Minimum Delivery is $50!{" "}
            </span>
          )}
          <b>Total: ${total.toFixed(2)}</b>
        </p>
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {noDelivery ? (
            <div />
          ) : (
            <div>
              <a
                className="blueButton"
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  this.toCustomerInfo(total);
                }}
                onClick={() => {
                  this.toCustomerInfo(total);
                }}
              >
                Your Info
              </a>
              <br />
              <br />
            </div>
          )}
        </div>
        <SendPriceToRedux
          subtotal={subtotal}
          total={total}
          setSubtotal={this.setSubtotal}
          setTotal={this.setTotal}
        />
      </div>
    );
  }
}

priceInfo.propTypes = {
  setSubtotal: PropTypes.func.isRequired,
  setTip: PropTypes.func.isRequired,
  setTotal: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tip: PropTypes.number.isRequired,
  orderType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(priceInfo);
